<template>
  <div class="main-content">
    <GroupInfo v-if="groupInfo !== null" :group-info="groupInfo"></GroupInfo>
    <div class="bottom-btn-block">
      <a class="btn-cta" @click.prevent="shareBtn">分享</a>
    </div>
    <SocialShareGroup
      :url="shareUrl"
      :shortify-url="true"
      :share-group-mode="true"
      :is-company-member="companyMember"
      :group-id="groupId"
    ></SocialShareGroup>
  </div>
</template>

<script>
import GroupInfo from '@/components/GroupList/GroupInfo';
import SocialShareGroup from '@/components/Modals/SocialShareGroup';
import GroupInfoMixin from '@/mixins/group-info';
import { mapGetters } from 'vuex';

export default {
  mixins: [GroupInfoMixin],
  components: {
    GroupInfo,
    SocialShareGroup
  },
  computed: {
    ...mapGetters(['isCompanyMember']),
    shareUrl() {
      return `${process.env.VUE_APP_GROUP_BASE}/${this.groupId}`;
    },
    //避免更新速度時間差
    companyMember() {
      return (
        this.groupInfo?.auth_by_company_member === true ||
        this.isCompanyMember === true
      );
    }
  },
  methods: {
    shareBtn() {
      $('#modal-helper-share').modal('show');
    }
  }
};
</script>
